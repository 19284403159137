<template>
    <div>
        <section class="section intro">
            <div class="level">
                <div class="level-left">
                    <h1 class="title has-text-centered-mobile is-size-5-mobile">{{ $t('Projects') }}</h1>
                </div>
                <div class="level-right">
                    <div class="level-item">
                        <router-link to="/gis/create-project" class="button is-link">
                            <b-icon icon="plus"/>
                            <span>{{ $t('Create project') }}</span>
                        </router-link>
                    </div>
                </div>
            </div>
        </section>
        <section class="section">
            <b-table
                v-if="projects && projects.length"
                :data="normalizedProjects"
                :paginated="isPaginated"
                :per-page="perPage"
                :current-page.sync="currentPage"
                :pagination-simple="isPaginationSimple"
                :pagination-position="paginationPosition"
                :default-sort-direction="defaultSortDirection"
                :pagination-rounded="isPaginationRounded"
                :sort-icon="sortIcon"
                :sort-icon-size="sortIconSize"
                default-sort="projectName"
                aria-next-label="Next page"
                aria-previous-label="Previous page"
                aria-page-label="Page"
                aria-current-label="Current page"
                :debounce-search="1000"
            >
                <b-table-column field="projectId" label="ID" sortable searchable numeric :width="100" v-slot="props">
                    {{ props.row.projectId }}
                </b-table-column>

                <b-table-column field="projectName" :label="$t('Project name')" sortable searchable v-slot="props">
                    <strong>{{ props.row.projectName }}</strong>
                </b-table-column>

                <b-table-column field="description" :label="$t('Description')" sortable searchable v-slot="props">
                    {{ props.row.description }}
                </b-table-column>

                <b-table-column field="cityModule" :label="$t('City')" sortable searchable v-slot="props">
                    {{ props.row.cityModule }}
                </b-table-column>

                <b-table-column field="projectType" :label="$t('Type')" sortable searchable v-slot="props">
                    {{ props.row.projectType }}
                </b-table-column>

                <b-table-column field="fileAccessType" :label="$t('Access')" sortable searchable centered v-slot="props">
                    {{ props.row.fileAccessType }}
                </b-table-column>

                <b-table-column field="timeStamp" :label="$t('Date')" sortable searchable centered v-slot="props">
                    {{ formatDate(props.row.timeStamp) }}
                </b-table-column>

                <b-table-column :label="$t('Options')" v-slot="props" centered :width="200">
                    <div class="buttons are-small is-centered">
                        <router-link :to="`/gis/project/${props.row.projectId}`" class="button is-link">{{ $t('View') }}</router-link>
                        <a href="#" class="button is-danger" @click.prevent="confirmDeleteProject(props.row)">{{ $t('Delete') }}</a>
                    </div>
                </b-table-column>
            </b-table>
            <div v-else class="has-text-centered has-text-danger">
                {{ $t('There are no projects yet.') }}
            </div>
        </section>
        <b-loading v-model="isLoading"></b-loading>
    </div>
</template>

<script>
import {formatDate, getCityModule, getFileAccessType, getProjectType, replaceFlags} from "@/utils";
import {mapGetters} from "vuex";

export default {
    name: "Projects",
    computed: {
        ...mapGetters({
            selectedCityModule: 'GET_CITY_MODULE'
        })
    },
    data() {
        return {
            projects: null,
            normalizedProjects: [],
            isLoading: false,
            isPaginated: true,
            isPaginationSimple: false,
            isPaginationRounded: false,
            paginationPosition: 'bottom',
            defaultSortDirection: 'asc',
            sortIcon: 'arrow-up',
            sortIconSize: 'is-small',
            currentPage: 1,
            perPage: 20
        }
    },
    mounted() {
        document.title = this.$t('Projects') + ' - GIS Solution'
        this.$scrollToTop()
        this.getProjects()
    },
    watch: {
        '$i18n.locale'() {
            document.title = this.$t('Projects') + ' - GIS Solution'
        },
        selectedCityModule() {
            this.getProjects()
        }
    },
    methods: {
        getProjects() {
            this.isLoading = true
            this.$store.dispatch('getAllProjects').then(response => {
                this.isLoading = false
                this.projects = response.data
                if (this.selectedCityModule) {
                    this.projects = this.projects.filter(
                        p => p.CityModuleID === this.selectedCityModule.cityModuleId
                    )
                }
                this.normalizeProjects()
            }).catch(error => {
                this.isLoading = false
                this.$buefy.toast.open({
                    duration: 3000,
                    message: this.$t(error.response ? error.response.data.message : error),
                    type: 'is-danger'
                })
            })
        },
        confirmDeleteProject(project) {
            this.$buefy.dialog.confirm({
                title: this.$t('Deleting project'),
                message: this.$t('Are you sure you want to <b>delete</b> this project? This action cannot be undone.'),
                confirmText: this.$t('Delete'),
                cancelText: this.$t('Cancel'),
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => this.deleteProject(project)
            })
        },
        deleteProject(project) {
            this.isLoading = true
            this.$store.dispatch('deleteProject', project).then(response => {
                this.isLoading = false
                this.$buefy.toast.open({
                    duration: 3000,
                    message: replaceFlags(this.$t('Project %s has been deleted!'), [project.projectName]),
                    type: 'is-success'
                })
                this.getProjects()
            }).catch(error => {
                this.isLoading = false
                this.$buefy.toast.open({
                    duration: 3000,
                    message: this.$t(error.response ? error.response.data.message : error),
                    type: 'is-danger'
                })
            })
        },
        projectType(id) {
            const type = getProjectType(id)
            if (type) {
                return type.title
            }
            return ''
        },
        fileAccessType(id) {
            const type = getFileAccessType(id)
            if (type) {
                return type.title
            }
            return ''
        },
        cityModule(id) {
            const type = getCityModule(id)
            if (type) {
                return type.title
            }
            return ''
        },
        formatDate(date) {
            return formatDate(date, this.$store.state.language, 'DD-MM-YYYY')
        },
        normalizeProjects() {
            this.normalizedProjects = []
            for (let x = 0; x < this.projects.length; x++) {
                const project = this.projects[x]
                this.normalizedProjects.push({
                    ...project,
                    cityModule: this.cityModule(project.CityModuleID),
                    projectType: this.projectType(project.ProjectTypeID),
                    fileAccessType: this.fileAccessType(project.FileAccessTypeID)
                })
            }
        }
    }
}
</script>

<style scoped>

</style>
